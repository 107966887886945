import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mx-0 px-0"
  }, [_vm.addressExists == 'true' ? _c('div', {
    staticClass: "mx-0 px-0"
  }, [_c('template', {
    slot: "progress"
  }, [_c(VProgressLinear, {
    attrs: {
      "color": "primary",
      "height": "10",
      "indeterminate": ""
    }
  })], 1), _vm.editContactData ? _c('ContactDataDialog', {
    attrs: {
      "address": _vm.address
    },
    on: {
      "closeDialog": function ($event) {
        _vm.editContactData = false;
      },
      "saved": _vm.updateContactData,
      "error": function ($event) {
        this.failMessage = 'Beim Speichern der Kontaktdaten ist ein unerwarteter Fehler aufgetreten!';
        this.errorDialog = true;
      }
    }
  }) : _vm._e(), _vm.showAddressPicturesDialog ? _c('AddressPicturesDialog', {
    attrs: {
      "address": _vm.address,
      "opened": true
    },
    on: {
      "close-dialog": function ($event) {
        return _vm.closeAddressPicturesDialog();
      }
    }
  }) : _vm._e(), _vm.showMap ? _c('l-map', {
    staticStyle: {
      "width": "100%",
      "height": "350px",
      "z-index": "1"
    },
    attrs: {
      "zoom": 17.5,
      "center": _vm.mapsAddressObject,
      "options": _vm.mapOptions
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url,
      "attribution": _vm.attribution
    }
  }), _c('l-marker', {
    attrs: {
      "lat-lng": _vm.mapsAddressObject
    }
  }, [_c('l-popup', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.address.name))]), _c('br'), _vm._v(" " + _vm._s(_vm.address.address.street)), _c('br'), _vm._v(" " + _vm._s(_vm.address.city) + " " + _vm._s(_vm.address.postcode) + " ")])])], 1)], 1) : _vm._e(), _c(VCardTitle, {
    staticClass: "ml-4 mt-4 mb-n6 d-flex justify-space-between",
    staticStyle: {
      "font-size": "3vh"
    },
    attrs: {
      "lang": "de"
    }
  }, [_vm.isMobile() ? _c('h4', {
    staticClass: "mr-8 font-weight-bold text-upercase text-break",
    staticStyle: {
      "word-wrap": "break-word",
      "hyphens": "auto"
    }
  }, [_c(VRow, [_vm.address.firstname == undefined && _vm.address.lastname == undefined ? _c('span', [_vm._v(" " + _vm._s(_vm.address.name) + " "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.description != undefined && _vm.address.description.length != 0,
      expression: "\n                address.description != undefined &&\n                  address.description.length != 0\n              "
    }]
  }, [_vm._v(" (" + _vm._s(_vm.address.description) + ") ")])]) : _c('span', [_vm._v(" " + _vm._s(_vm.address.firstname + ' ' + _vm.address.lastname) + " "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.description != undefined && _vm.address.description.length != 0,
      expression: "\n                address.description != undefined &&\n                  address.description.length != 0\n              "
    }]
  }, [_vm._v(" (" + _vm._s(_vm.address.description) + ") ")])]), _c('LBAButton', {
    staticClass: "ml-3",
    attrs: {
      "buttonType": 'primary',
      "buttonStyle": 'icon'
    },
    on: {
      "click": function ($event) {
        _vm.showAddressPicturesDialog = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VIcon, [_vm._v("mdi-image-multiple")])];
      },
      proxy: true
    }], null, false, 1583517668)
  })], 1)], 1) : _c('h4', {
    staticClass: "mr-8 font-weight-bold text-upercase text-break"
  }, [_vm.address.firstname == undefined && _vm.address.lastname == undefined ? _c('span', [_vm._v(" " + _vm._s(_vm.address.name) + " "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.description != undefined && _vm.address.description.length != 0,
      expression: "\n              address.description != undefined &&\n                address.description.length != 0\n            "
    }]
  }, [_vm._v(" (" + _vm._s(_vm.address.description) + ") ")])]) : _c('span', [_vm._v(" " + _vm._s(_vm.address.firstname + ' ' + _vm.address.lastname) + " "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.description != undefined && _vm.address.description.length != 0,
      expression: "\n              address.description != undefined &&\n                address.description.length != 0\n            "
    }]
  }, [_vm._v(" (" + _vm._s(_vm.address.description) + ") ")])]), _c('LBAButton', {
    attrs: {
      "buttonType": 'primary',
      "buttonStyle": 'icon',
      "buttonSize": 'large'
    },
    on: {
      "click": function ($event) {
        _vm.showAddressPicturesDialog = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VIcon, [_vm._v("mdi-image-multiple")])];
      },
      proxy: true
    }], null, false, 1583517668)
  })], 1), _c('div', _vm._l(_vm.allRoles, function (role, index) {
    return _c('div', {
      key: index
    }, [_c('AddressTypeChip', {
      attrs: {
        "name": role.name,
        "icon": role.icon
      }
    })], 1);
  }), 0)]), _c(VCardText, {
    staticClass: "d-flex justify-space-between flex-wrap"
  }, [_c('div', {
    staticClass: "elevation-0 pa-4 mb-4 col-md-6 col-12"
  }, [_c('h3', {
    staticClass: "mb-4 text-h6"
  }, [_vm._v("Anschrift")]), _c('div', {
    staticClass: "body-1 mb-4"
  }, [_c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [_vm._v(" Straße: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.street) + " ")])], 1), _c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [_vm._v(" Ort: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.zip) + " " + _vm._s(_vm.address.address.city) + " ")])], 1), _c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [_vm._v(" Land: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_c('span', [_vm._v(_vm._s(_vm.address.address.countryName))])])], 1), _c(VRow, [_c(VCol, {
    staticClass: "col-lg-4 pl-2 col-12"
  }, [_c('LBAButton', {
    staticClass: "col-12 mt-2",
    attrs: {
      "buttonStyle": 'default',
      "buttonType": 'grey'
    },
    on: {
      "click": _vm.openAddressInGoogleMaps
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VIcon, {
          staticClass: "mr-2"
        }, [_vm._v("mdi-google-maps")]), _vm._v("Google Maps")];
      },
      proxy: true
    }], null, false, 1889758705)
  })], 1), _c(VCol, {
    staticClass: "col-lg-4 pl-2 col-12"
  }, [_c('LBAButton', {
    staticClass: "col-12 mt-2",
    attrs: {
      "buttonType": 'grey'
    },
    on: {
      "click": _vm.openAddressInGoogleMapsWithNavigation
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VIcon, {
          staticClass: "mr-2"
        }, [_vm._v("mdi-navigation")]), _vm._v(" Navigation")];
      },
      proxy: true
    }], null, false, 314487676)
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "elevation-0 pa-4 mb-4 col-md-6 col-12"
  }, [_c('h3', {
    staticClass: "mb-4 text-h6"
  }, [_vm._v("Kontaktdaten")]), _c('div', {
    staticClass: "body-1 mb-4"
  }, [_c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone")]) : _vm._e(), _vm._v(" Telefon: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c('a', {
    attrs: {
      "href": _vm.telLink
    }
  }, [_vm._v(_vm._s(_vm.address.tel))])])], 1), _c(VRow, {
    staticClass: "ml-0 mt-4"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone-outline")]) : _vm._e(), _vm._v(" Telefon 2: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c('a', {
    attrs: {
      "href": _vm.telLink
    }
  }, [_vm._v(_vm._s(_vm.address.tel2))])])], 1), _c(VRow, {
    staticClass: "ml-0 mt-4"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-cellphone")]) : _vm._e(), _vm._v(" Mobil: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c('a', {
    attrs: {
      "href": _vm.telLink
    }
  }, [_vm._v(" " + _vm._s(_vm.address.mobile) + " ")])])], 1), _c(VRow, {
    staticClass: "ml-0 mt-6"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email")]) : _vm._e(), _vm._v(" E-Mail: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c('a', {
    attrs: {
      "href": _vm.mailLink
    }
  }, [_vm._v(" " + _vm._s(_vm.address.email))])])], 1), _c(VRow, [_c('LBAButton', {
    staticClass: "mt-4 ml-2",
    attrs: {
      "buttonStyle": 'default',
      "buttonType": 'grey',
      "buttonSize": 'small'
    },
    on: {
      "click": function ($event) {
        _vm.editContactData = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm._v("Bearbeiten")];
      },
      proxy: true
    }], null, false, 2891916337)
  })], 1)], 1)])]), _c(VDivider, {
    staticClass: "mx-4"
  }), _c('div', {
    staticClass: "tabWidthFill"
  }, [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm.address.patient !== undefined && _vm.address.patient !== null ? _c(VTab, {
    attrs: {
      "href": "#patientInformation"
    }
  }, [_vm._v("Patientenprofil")]) : _vm._e(), _vm.address.employee !== undefined && _vm.address.employee !== null ? _c(VTab, {
    attrs: {
      "href": "#employeeInformation"
    }
  }, [_vm._v("Mitarbeiter")]) : _vm._e(), _vm.address.doctor !== undefined && _vm.address.doctor !== null ? _c(VTab, {
    attrs: {
      "href": "#doctorInformation"
    }
  }, [_vm._v("Arzt")]) : _vm._e(), _vm.address.company !== undefined && _vm.address.company !== null ? _c(VTab, {
    attrs: {
      "href": "#companyInformation"
    }
  }, [_vm._v("Unternehmen")]) : _vm._e(), _vm.address.supplier !== undefined && _vm.address.supplier !== null ? _c(VTab, {
    attrs: {
      "href": "#supplierInformation"
    }
  }, [_vm._v("Lieferant")]) : _vm._e(), _vm.address.healthInsuranceCompany !== undefined && _vm.address.healthInsuranceCompany !== null ? _c(VTab, {
    attrs: {
      "href": "#healthInsuranceCompanyInformation"
    }
  }, [_vm._v("Krankenkasse")]) : _vm._e(), _c(VTab, {
    attrs: {
      "href": "#contacts"
    }
  }, [_vm._v("Ansprechpartner")]), _c(VTab, {
    attrs: {
      "href": "#customerGroups"
    }
  }, [_vm._v("Kundengruppen-Zuordnung")]), _c(VTab, {
    attrs: {
      "href": "#interactions"
    }
  }, [_vm._v("Interaktionen")]), _c(VTab, {
    attrs: {
      "href": "#documents"
    }
  }, [_vm._v("Dokumente")]), _vm.address.supplier !== undefined && _vm.address.supplier !== null ? _c(VTab, {
    attrs: {
      "href": "#ekRevenueStatistics"
    }
  }, [_vm._v("EK - Umsatzstatistik")]) : _vm._e(), _vm.address.consumer == true || _vm.address.company !== undefined && _vm.address.company !== null || _vm.address.patient !== undefined && _vm.address.patient !== null ? _c(VTab, {
    attrs: {
      "href": "#revenue"
    }
  }, [_vm._v("VK - Umsatzstatistik")]) : _vm._e(), _vm.adressTypeDetailedName.includes('Krankenk.') ? _c(VTab, {
    attrs: {
      "href": "#KKrevenueStatistics"
    }
  }, [_vm._v("KV-Statistik")]) : _vm._e(), _vm.adressTypeDetailedName.includes('Arzt') ? _c(VTab, {
    attrs: {
      "href": "#prescriptionStatistics"
    }
  }, [_vm._v("Verordnungsstatistik")]) : _vm._e()], 1)], 1), _c(VTabsItems, {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    attrs: {
      "value": "patientInformation"
    }
  }, [_c('PatientinformationTab', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "employeeInformation"
    }
  }, [_c('EmployeeInformationTab', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "doctorInformation"
    }
  }, [_c('DoctorInformationTab', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "companyInformation"
    }
  }, [_c('CompanyInformationTab', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "supplierInformation"
    }
  }, [_c('SupplierInformationTab', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "healthInsuranceCompanyInformation"
    }
  }, [_c('HealthInsuranceCompanyInformationTab', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "contacts"
    }
  }, [_c('ContactPersons', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "customerGroups"
    }
  }, [_c('CustomerGroups', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "interactions"
    }
  }, [_c('InteractionsTable', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "documents"
    }
  }, [_c('DocumentsTable', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "revenue"
    }
  }, [_c(VContainer, [_c('VK', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  }), _c('router-view')], 1)], 1), _c(VTabItem, {
    attrs: {
      "value": "prescriptionStatistics"
    }
  }, [_c('Prescription', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "KKrevenueStatistics"
    }
  }, [_c('KKStatistics', {
    attrs: {
      "lfdnr": _vm.lfdnr
    }
  })], 1), _c(VTabItem, {
    attrs: {
      "value": "ekRevenueStatistics"
    }
  }, [_c(VContainer, [_c('ArticleTypes', {
    attrs: {
      "lfdnr": this.lfdnr
    }
  }), _c('router-view')], 1)], 1)], 1)], 2) : _vm.addressExists == 'loading' ? _c('div', {
    attrs: {
      "max-width": "100%"
    }
  }, [_c('template', {
    slot: "progress"
  }, [_c(VProgressLinear, {
    attrs: {
      "color": "primary",
      "height": "10",
      "indeterminate": ""
    }
  })], 1), _c(VCardTitle, {
    staticClass: "ml-4 mt-4 mb-n6 d-flex justify-space-between",
    staticStyle: {
      "font-size": "3.5vh"
    },
    attrs: {
      "lang": "de"
    }
  }, [_vm.isMobile() ? _c('h4', {
    staticClass: "mr-8 font-weight-bold text-upercase text-break",
    staticStyle: {
      "word-wrap": "break-word",
      "hyphens": "auto"
    }
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "heading",
      "width": "50vw"
    }
  })], 1) : _c('h4', {
    staticClass: "mr-8 font-weight-bold text-upercase text-break"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "heading",
      "width": "50vw"
    }
  })], 1), _c('div', {
    class: _vm.isMobile() ? 'col col-sm-12 col-lg-4 col-xl-3' : 'd-inline-flex'
  })]), _c(VCardText, {
    staticClass: "d-flex justify-space-between flex-wrap"
  }, [_c('div', {
    staticClass: "elevation-0 pa-4 mb-4 col-md-6 col-12"
  }, [_c('h3', {
    staticClass: "mb-4 text-h6"
  }, [_vm._v("Anschrift")]), _c('div', {
    staticClass: "body-1 mb-4"
  }, [_c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [_vm._v(" Straße: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "60%"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [_vm._v(" Ort: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "40%"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [_vm._v(" Land: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "70%"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "elevation-0 pa-4 mb-4 col-md-6 col-12"
  }, [_c('h3', {
    staticClass: "mb-4 text-h6"
  }, [_vm._v("Kontaktdaten")]), _c('div', {
    staticClass: "body-1 mb-4"
  }, [_c(VRow, {
    staticClass: "ml-0"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone")]) : _vm._e(), _vm._v(" Telefon: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "60%"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "ml-0 mt-4"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone-outline")]) : _vm._e(), _vm._v(" Telefon 2: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "40%"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "ml-0 mt-4"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-cellphone")]) : _vm._e(), _vm._v(" Mobil: ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "50%"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "ml-0 mt-6"
  }, [_c(VCol, {
    staticClass: "pa-0 col-4"
  }, [!_vm.isMobile() ? _c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email")]) : _vm._e(), _vm._v(" E-Mail: " + _vm._s(_vm.address.mobile) + " ")], 1), _c(VCol, {
    staticClass: "pa-0"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "type": "text",
      "width": "90%"
    }
  })], 1)], 1)], 1)])]), _c(VDivider, {
    staticClass: "mx-4"
  })], 2) : _vm.addressExists == 'false' ? _c('div', {
    attrs: {
      "max-width": "100%"
    }
  }, [_c(VCard, {
    staticClass: "pa-5",
    staticStyle: {
      "border-radius": "15px"
    }
  }, [_c(VLayout, {
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center mb-4",
    attrs: {
      "xs12": ""
    }
  }, [_c('h3', [_vm._v(" Die angegebene Adresse konnte nicht gefunden werden. ")])]), _c(VFlex, {
    attrs: {
      "xs12": ""
    }
  }, [_c(VImg, {
    staticClass: "mx-auto my-auto",
    attrs: {
      "src": require("@/assets/svg/undraw_warning_re_eoyh.svg"),
      "max-width": "350px",
      "contain": ""
    }
  })], 1), _c(VFlex, {
    staticClass: "text-center mt-4",
    attrs: {
      "xs12": ""
    }
  }, [_c('LBAButton', {
    attrs: {
      "buttonType": 'primary'
    },
    on: {
      "click": function ($event) {
        return _vm.gotoSearchPage();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-magnify")]), _vm._v(" Zurück zur Suche")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };