import { mapState } from 'vuex';
import axios from 'axios';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import ArticlesVK from './Articles.vue';
export default {
  data: () => ({
    headers: [{
      text: 'Artikelkategorie',
      value: 'name',
      align: 'left'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }],
    loading: true,
    deliveryData: [],
    invoiceData: [],
    currencyFormatService,
    search: '',
    articleCodeTemp: '',
    articlesShow: false,
    articleCategory: ''
  }),
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showInvoices: state => state.vkCustomerRevenueStatistic.showInvoices
    }),
    showInvoicesLocal: {
      get() {
        return this.showInvoices;
      },
      set(value) {
        this.$store.dispatch('setShowInvoices', value);
      }
    }
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    },
    articleTypeCode: {
      type: String,
      required: true
    }
  },
  components: {
    ArticlesVK
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadArticleCategoriesStatistics();
      this.openArticleCategory(this.item);
    },
    loadArticleCategoriesStatistics() {
      console.warn(this.articleTypeCode);
      this.loading = true;
      axios.get(`v1/statistics/customers/${this.lfdnr}/articleTypes/${this.articleTypeCode}/articleCategories`, {
        params: {
          from: this.filterFromDate,
          to: this.filterToDate
        }
      }).then(data => {
        this.deliveryData = data.data.deliveryData;
        this.invoiceData = data.data.invoiceData;
        this.loading = false;
      });
    },
    openArticleCategory(item) {
      console.warn(item);
      if (this.articleCodeTemp == item.code) {
        this.articleCodeTemp = '';
        this.articlesShow = false;
      } else {
        this.articleCodeTemp = item.code;
        this.articleCategory = item.name;
        this.articlesShow = true;
      }
    },
    itemRowBackground2: function (item) {
      if (item.code == this.articleCodeTemp) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    }
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    filterToDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    articleType: function () {
      this.init();
      this.articlesShow = false;
    }
  }
};