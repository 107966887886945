import { mapState } from 'vuex';
import addressService from '@/services/crm/addressService.js';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
import axios from 'axios';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import ArticleCategoriesVK from '@/components/statistics/vkCustomerRevenue/ArticleCategories.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter,
    ArticleCategoriesVK
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showInvoices: state => state.vkCustomerRevenueStatistic.showInvoices
    }),
    showInvoicesLocal: {
      get() {
        return this.showInvoices;
      },
      set(value) {
        this.$store.dispatch('setShowInvoices', value);
      }
    }
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    headers: [{
      text: 'Artikeltype',
      value: 'name',
      align: 'left'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }],
    deliveryData: [],
    invoiceData: [],
    loading: true,
    currencyFormatService,
    search: '',
    articleCodeTemp: '',
    articleType: '',
    articleCategoryShow: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
        this.loadArticleTypeStatistics();
        document.getElementById('toScroll').scrollIntoView({
          behavior: 'smooth'
        });
        this.openArticleType(this.item);
      });
    },
    loadArticleTypeStatistics() {
      var lfdnr = this.address.key.lfdnr;
      console.warn(lfdnr);
      this.loading = true;
      axios.get(`v1/statistics/customers/${lfdnr}/articleTypes`, {
        params: {
          from: this.filterFromDate,
          to: this.filterToDate
        }
      }).then(data => {
        this.deliveryData = data.data.deliveryData;
        this.invoiceData = data.data.invoiceData;
        this.loading = false;
      });
    },
    openArticleType(item) {
      console.warn(item);
      if (this.articleCodeTemp == item.code) {
        this.articleCodeTemp = '';
        this.articleCategoryShow = false;
      } else {
        this.articleCodeTemp = item.code;
        this.articleType = item.name;
        //console.log(this.articleType)
        this.articleCategoryShow = true;
      }
    },
    itemRowBackground2: function (item) {
      console.log('code: ' + item.code + 'artikelcodetemp: ' + this.articleType);
      if (item.code == this.articleCodeTemp) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    }
  },
  watch: {
    lfdnr() {
      this.init();
    },
    filterFromDate: function () {
      this.loadArticleTypeStatistics();
    },
    filterToDate: function () {
      this.loadArticleTypeStatistics();
    }
  }
};