import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import documentService from '@/services/crm/documentService.js';
import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import { DateTime } from 'luxon';
export default {
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  components: {
    OpenLinkEyeComponent,
    ErrorDialog
  },
  data() {
    return {
      search: '',
      expanded: [],
      sortDesc: true,
      items: [],
      headers: [{
        text: 'Datum',
        align: 'start',
        value: 'date'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Typ',
        value: 'type'
      }, {
        text: 'Status',
        value: 'state',
        sortable: false
      }, {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12em'
      }, {
        value: 'positionsString',
        align: ' d-none' // for invisibility, keeps search ability, space is important!
      }],
      positionHeaders: [{
        text: 'Artikel-Nr.',
        value: 'articleNr'
      }, {
        text: 'Artikel-Bezeichnung',
        value: 'articleDescription'
      }, {
        text: 'Artikel-Bezeichnung 2',
        value: 'articleDescription2'
      }],
      selectedTreeViewArray: [],
      emptyTreeViewItems: [],
      treeViewItems: [{
        wf_cid: 1,
        name: 'Applications :',
        children: [{
          wf_cid: 2,
          name: 'Calendar : app'
        }, {
          wf_cid: 3,
          name: 'Chrome : app'
        }, {
          wf_cid: 4,
          name: 'Webstorm : app'
        }]
      }, {
        wf_cid: 5,
        name: 'Documents :',
        children: [{
          wf_cid: 6,
          name: 'vuetify :',
          children: [{
            wf_cid: 7,
            name: 'src :',
            children: [{
              wf_cid: 8,
              name: 'index : ts'
            }, {
              wf_cid: 9,
              name: 'bootstrap : ts'
            }]
          }]
        }, {
          wf_cid: 10,
          name: 'material2 :',
          children: [{
            wf_cid: 11,
            name: 'src :',
            children: [{
              wf_cid: 12,
              name: 'v-btn : ts'
            }, {
              wf_cid: 13,
              name: 'v-card : ts'
            }, {
              wf_cid: 14,
              name: 'v-window : ts'
            }]
          }]
        }]
      }, {
        wf_cid: 15,
        name: 'Downloads :',
        children: [{
          wf_cid: 16,
          name: 'October : pdf'
        }, {
          wf_cid: 17,
          name: 'November : pdf'
        }, {
          wf_cid: 18,
          name: 'Tutorial : html'
        }]
      }, {
        wf_cid: 19,
        name: 'Videos :',
        children: [{
          wf_cid: 20,
          name: 'Tutorials :',
          children: [{
            wf_cid: 21,
            name: 'Basic layouts : mp4'
          }, {
            wf_cid: 22,
            name: 'Advanced techniques : mp4'
          }, {
            wf_cid: 23,
            name: 'All about app : dir'
          }]
        }, {
          wf_cid: 24,
          name: 'Intro : mov'
        }, {
          wf_cid: 25,
          name: 'Conference introduction : avi'
        }]
      }],
      failMessage: '',
      errorDialog: false,
      date: null,
      date1: '',
      date2: '',
      datePickerMenu1: false,
      datePickerMenu2: false,
      selectedDocumentType: null,
      selectedStatus: null,
      allDocumentType: null,
      allStatus: null,
      documentTypSelectorItems: ['item1', 'item2', 'item3', 'item4'],
      statusSelectorItems: ['10 offen', 'item2', 'item3'],
      availableDocumentTypes: [{
        id: 30,
        name: 'Angebot'
      }, {
        id: 35,
        name: 'Auftrag'
      }]
    };
  },
  computed: {
    ...mapState({
      documents: state => state.crmDocuments.documents
    }),
    filteredData() {
      if (this.search === '') return this.documentsWithPreparedPositions;else {
        const searchLower = this.search.toLowerCase();

        // Use the filter method to create a new array with matching entries
        const temp = this.documentsWithPreparedPositions.filter(item => {
          const matchesDocument = item.nr.toLowerCase().includes(searchLower) || item.type.toLowerCase().includes(searchLower) || item.shortType.toLowerCase().includes(searchLower) || item.state.toLowerCase().includes(searchLower);

          // Check if any positions' articleDescription matches the search string
          const matchesPositions = item.positions.some(position => position.articleDescription.toLowerCase().includes(searchLower));

          // Return true if either the document or any position matches
          return matchesDocument || matchesPositions;
        });
        return temp;
      }
    },
    isUserBusinessAuthorized() {
      console.log(this.$store.getters.userHasApp('Business'));
      return this.$store.getters.userHasApp('Business');
    },
    documentsFiltered() {
      this.fillFilter();
      return this.documents.filter(item => item.show);
    },
    documentsWithPreparedPositions() {
      let temp = this.documents.filter(item => {
        let startDateTrue = this.date1 == null || this.date1 === '' || item.date > this.date1;
        let endDateTrue = this.date2 == null || this.date2 === '' || item.date < this.date2;
        let documentTypeTrue = this.selectedDocumentType == null || item.type === this.selectedDocumentType;
        let statusTrue = this.selectedStatus == null || item.state === this.selectedStatus;
        return startDateTrue && endDateTrue && documentTypeTrue && statusTrue;
      });
      let beginDateFormat = new Date(this.date1);
      let endDateFormat = new Date(this.date2);
      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum');
      }
      return this.documents.map(d => {
        let posString = '';
        for (const pos of d.positions) posString += `${pos.articleNr} ${pos.articleDescription} ${pos.articleDescription2} `;
        return temp && {
          ...d,
          positionsString: posString
        };
      }) && temp;
    },
    selectedTreeViewItem() {
      if (this.selectedTreeViewArray.length == 0) return undefined;
      const id = this.selectedTreeViewArray[0];
      var item = this.documents.find(doc => doc.wf_cid === id);
      return this.convertItemToTreeViewItem(item);
    },
    formattedDate1() {
      return this.date1 ? DateTime.fromISO(this.date1).toLocaleString() : '';
    },
    formattedDate2() {
      return this.date2 ? DateTime.fromISO(this.datePickerMenu2).toLocaleString() : '';
    },
    formattedTreeItems() {
      return this.treeViewItems;
    }
  },
  methods: {
    init() {
      this.loadDocuments();
      this.fillFilter();
    },
    convertItemToTreeViewItem(item) {
      return {
        wf_cid: item.wf_cid,
        name: item.name,
        children: item.childrenDocuments
      };
    },
    loadDocuments() {
      this.$store.dispatch('loadDocuments', this.lfdnr).then(this.loading = false);
    },
    printDocument(item) {
      documentService.printDocument(item).catch(error => {
        this.failMessage = 'Das Drucken ist fehlgeschlagen!';
        this.errorDialog = true;
        console.error(error);
        item.printLoading = false;
      });
    },
    getTreeViewItems(item) {
      let leaf = {
        wf_cid: item.wf_cid,
        name: item.shortType + ' ' + item.nr
      };
      if (item.childrenDocuments.length != 0) {
        //parent document exists
        leaf.children = [this.getTreeViewItems(item.childrenDocuments[0])];
        return leaf;
      } else {
        //parent document does not exists
        return leaf;
      }
    },
    openRow(item) {
      if (item.length != 0) {
        this.treeViewItems = [this.getTreeViewItems(item[0])];
        this.selectedTreeViewArray = [];
      }
    },
    resetFilter() {
      this.date1 = null;
      this.date2 = null;
      this.selectedDocumentType = null;
      this.selectedStatus = null;
    },
    fillFilter() {
      if (this.date1 == null || '') {
        this.date1 = '';
      }
      if (this.date2 == null || '') {
        this.date2 = '';
      }
      this.documents.forEach(elem => {
        this.allDocumentType.push(elem.selectedDocumentType);
        this.allStatus.push(elem.selectedStatus);
      });
    },
    displayTreeviewData() {
      return true;
    }
  },
  watch: {
    lfdnr() {
      this.init();
    }
  },
  mounted() {
    this.init();
  }
};