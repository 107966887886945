import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "id": "toScroll"
    }
  }, [_vm.address.firstname !== undefined ? _c('SiteHeaderComponent', {
    attrs: {
      "header": _vm.address.firstname + ' ' + _vm.address.lastname
    }
  }) : _c('SiteHeaderComponent', {
    attrs: {
      "header": _vm.address.name
    }
  }), _c('div', {
    staticClass: "d-flex justify-end"
  }), _c('div', [_c('TimeFilter', {
    ref: "timeFilter"
  })], 1), _c(VBottomNavigation, {
    staticClass: "ml-10 pb-2 pt-1",
    attrs: {
      "fixed": "",
      "horizontal": "",
      "scroll-threshold": "500",
      "hide-on-scroll": ""
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('span', [_vm.address.firstname !== undefined ? _c('b', [_vm._v(_vm._s(_vm.address.firstname) + " " + _vm._s(_vm.address.lastname))]) : _c('b', [_vm._v(_vm._s(_vm.address.name))])]), _c('br'), _c('span', [_c('b', [_vm._v(_vm._s(_vm.$store.state.statisticsTimeFilter.fromDate))]), _vm._v(" bis "), _c('b', [_vm._v(_vm._s(_vm.$store.state.statisticsTimeFilter.toDate))])])])]), _c(VDivider), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c(VSwitch, {
    staticClass: "ml-4",
    attrs: {
      "label": "Rechnungs-/Lieferdokumente"
    },
    model: {
      value: _vm.showInvoicesLocal,
      callback: function ($$v) {
        _vm.showInvoicesLocal = $$v;
      },
      expression: "showInvoicesLocal"
    }
  }), _c(VDataTable, {
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.showInvoices === true ? _vm.deliveryData : _vm.invoiceData,
      "loading": _vm.loading,
      "search": _vm.search,
      "mobile-breakpoint": "800",
      "item-class": _vm.itemRowBackground2,
      "loading-text": "Daten werden geladen..."
    },
    on: {
      "click:row": _vm.openArticleType
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.currencyFormatService.formatEuro(item.revenue)) + " ")];
      }
    }, {
      key: "item.amount",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.amount.toLocaleString('de-DE')) + " ")];
      }
    }])
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.articleCategoryShow,
      expression: "articleCategoryShow"
    }]
  }, [_c('ArticleCategoriesVK', {
    attrs: {
      "lfdnr": _vm.lfdnr,
      "articleType": _vm.articleType,
      "articleTypeCode": _vm.articleCodeTemp
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };