import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "alignInformationTab"
  }, [_c(VForm, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Vertragsnummer",
      "append-icon": "mdi-numeric",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "contractNumber", $$v);
      },
      expression: "address.company.contractNumber"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "UID",
      "append-icon": "mdi-identifier",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.uid,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "uid", $$v);
      },
      expression: "address.company.uid"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "5",
      "xl": "5"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Kundengruppe",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.customerGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "customerGroupName", $$v);
      },
      expression: "address.company.customerGroupName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Verband",
      "prepend-inner-icon": "mdi-account-group",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.associationName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "associationName", $$v);
      },
      expression: "address.company.associationName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Branche",
      "prepend-inner-icon": "mdi-account-hard-hat",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.industrialSectorsName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "industrialSectorsName", $$v);
      },
      expression: "address.company.industrialSectorsName"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Lieferung und Bezahlung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-truck",
      "label": "Versandart",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.shippingMethodName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "shippingMethodName", $$v);
      },
      expression: "address.company.deliveryAndPayment.shippingMethodName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-truck-alert",
      "label": "Lieferbedingung",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.shippingConditionName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "shippingConditionName", $$v);
      },
      expression: "address.company.deliveryAndPayment.shippingConditionName"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "mt-5"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-cash",
      "label": "Zahlungsbedingung",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.paymentConditionName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "paymentConditionName", $$v);
      },
      expression: "address.company.deliveryAndPayment.paymentConditionName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Verkäufer",
      "prepend-inner-icon": "mdi-account",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.salesPersonName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "salesPersonName", $$v);
      },
      expression: "address.company.deliveryAndPayment.salesPersonName"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "mt-5"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "outlined": "",
      "label": "Sammelliefers. Int.",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.collectiveDeliveryNoteInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "collectiveDeliveryNoteInterval", $$v);
      },
      expression: "\n            address.company.deliveryAndPayment.collectiveDeliveryNoteInterval\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "outlined": "",
      "label": "Sammelr. Int.",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.collectiveInvoiceInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "collectiveInvoiceInterval", $$v);
      },
      expression: "\n            address.company.deliveryAndPayment.collectiveInvoiceInterval\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Lager",
      "prepend-inner-icon": "mdi-warehouse",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.warehouseName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "warehouseName", $$v);
      },
      expression: "address.company.deliveryAndPayment.warehouseName"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "5",
      "xl": "5",
      "md": "5"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Rabatt Typ",
      "outlined": "",
      "prepend-inner-icon": "mdi-file-percent",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "discountType", $$v);
      },
      expression: "address.company.deliveryAndPayment.discountType"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "suffix": "%",
      "label": "Rabattausmaß",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.discount,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "discount", $$v);
      },
      expression: "address.company.deliveryAndPayment.discount"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Verband ist Rechnungsadresse",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.associationIsInvoiceAddress,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "associationIsInvoiceAddress", $$v);
      },
      expression: "\n            address.company.deliveryAndPayment.associationIsInvoiceAddress\n          "
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "8",
      "md": "8"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Fibugruppe",
      "prepend-inner-icon": "mdi-account-group",
      "disabled": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.fibuGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "fibuGroupName", $$v);
      },
      expression: "address.company.deliveryAndPayment.fibuGroupName"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };