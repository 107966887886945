import "core-js/modules/es.array.push.js";
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import { mapState } from 'vuex';
import axios from 'axios';
import ArticleDetailsVK from './ArticleDetails.vue';
export default {
  data: () => ({
    headers: [{
      text: 'Bild',
      sortable: false,
      value: 'articleImages',
      align: 'start',
      width: '10%'
    }, {
      text: 'Artikel',
      value: 'name',
      align: 'left',
      width: '30%'
    }, {
      text: 'Artikelgruppe',
      value: 'articleGroup',
      align: 'left',
      width: '20%'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end',
      width: '20%'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end',
      width: '20%'
    }],
    loading: true,
    deliveryData: [],
    invoiceData: [],
    currencyFormatService,
    search: '',
    article: '',
    articleCode: '',
    articleDetailsShow: false,
    articleGroups: [],
    selectedArticleGroup: '',
    showTable: true
  }),
  mounted() {
    this.init();
  },
  components: {
    ArticleDetailsVK
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showInvoices: state => state.vkCustomerRevenueStatistic.showInvoices
    }),
    showInvoicesLocal: {
      get() {
        return this.showInvoices;
      },
      set(value) {
        this.$store.dispatch('setShowInvoices', value);
      }
    }
  },
  methods: {
    init() {
      this.loadArticlesStatistics();
      this.articleGroups = [];
      this.openArticle(this.item);
    },
    openImage(images) {
      var display = [];
      images.forEach(img => {
        display.push(img.image);
      });
      if (display[0] !== null) this.$viewerApi({
        images: display
      });
    },
    loadArticlesStatistics() {
      this.loading = true;
      axios.get(`v1/statistics/customers/${this.lfdnr}/articleTypes/${this.articleTypeCode}/articleCategories/${this.articleCategoryCode}`, {
        params: {
          from: this.filterFromDate,
          to: this.filterToDate
        }
      }).then(data => {
        this.deliveryData = data.data.deliveryData;
        this.invoiceData = data.data.invoiceData;
        this.deliveryData.forEach(element => {
          if (!this.articleGroups.includes(element.articleGroup)) {
            this.articleGroups.push(element.articleGroup);
          }
        });
        this.invoiceData.forEach(element => {
          if (!this.articleGroups.includes(element.articleGroup)) {
            this.articleGroups.push(element.articleGroup);
          }
        });
        this.resetFilter();
        this.loading = false;
      });
    },
    openArticle(item) {
      console.warn(item);
      if (this.articleCode == item.code) {
        this.articleCode = '';
        this.articleDetailsShow = false;
      } else {
        this.articleCode = item.code;
        this.article = item.name;
        this.articleDetailsShow = true;
      }
    },
    itemRowBackground2: function (item) {
      if (item.code == this.articleCode) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    },
    filterArticleGroup() {
      this.showTable = false;
      console.warn(this.selectedArticleGroup);
      console.warn(this.showInvoices);
      this.filteredDeliveryData = this.deliveryData;
      this.filteredInvoiceData = this.invoiceData;
      if (!this.showInvoices) {
        this.filteredInvoiceData = this.filteredInvoiceData.filter(obj => obj['articleGroup'] === this.selectedArticleGroup);
        console.warn(this.filteredInvoiceData);
        console.warn("ouihasdoihjasdoipj");
      } else {
        this.filteredDeliveryData = this.filteredDeliveryData.filter(obj => obj['articleGroup'] === this.selectedArticleGroup);
        console.warn(this.filteredDeliveryData);
      }
      this.showTable = true;
    },
    resetFilter() {
      this.showTable = false;
      this.filteredDeliveryData = this.deliveryData;
      this.filteredInvoiceData = this.invoiceData;
      this.selectedArticleGroup = '';
      this.showTable = true;
    }
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    },
    articleTypeCode: {
      type: String,
      required: true
    },
    articleCategory: {
      type: String,
      required: true
    },
    articleCategoryCode: {
      type: String,
      required: true
    }
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    filterToDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    articleCategory: function () {
      this.init();
    },
    articleType: function () {
      this.init();
    },
    selectedArticleGroup: function () {
      console.warn(this.$refs.table);
    }
  }
};