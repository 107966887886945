import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "grey--text text--darken-2 mb-5"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.articleType) + " -> " + _vm._s(_vm.articleCategory) + " -> Artikel ")])]), _c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c(VSwitch, {
    staticClass: "ml-4",
    attrs: {
      "label": "Rechnungs-/Lieferdokumente"
    },
    model: {
      value: _vm.showInvoicesLocal,
      callback: function ($$v) {
        _vm.showInvoicesLocal = $$v;
      },
      expression: "showInvoicesLocal"
    }
  }), _c(VDataTable, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTable,
      expression: "showTable"
    }],
    ref: "table",
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.showInvoices === true ? _vm.filteredDeliveryData : _vm.filteredInvoiceData,
      "loading": _vm.loading,
      "search": _vm.search,
      "mobile-breakpoint": "800",
      "item-class": _vm.itemRowBackground2,
      "loading-text": "Daten werden geladen..."
    },
    on: {
      "click:row": _vm.openArticle
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c(VTooltip, {
          attrs: {
            "top": "",
            "color": "red"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c(VBtn, _vm._g(_vm._b({
                staticClass: "white--text ml-5",
                staticStyle: {
                  "float": "right"
                },
                attrs: {
                  "color": "red"
                },
                on: {
                  "click": function ($event) {
                    return _vm.resetFilter();
                  }
                }
              }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v(" mdi-close-thick ")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("Filter löschen")])]), _c(VAutocomplete, {
          attrs: {
            "outlined": "",
            "dense": "",
            "label": "Artikelgruppe",
            "items": _vm.articleGroups
          },
          on: {
            "change": _vm.filterArticleGroup
          },
          model: {
            value: _vm.selectedArticleGroup,
            callback: function ($$v) {
              _vm.selectedArticleGroup = $$v;
            },
            expression: "selectedArticleGroup"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.articleImages",
      fn: function ({
        item
      }) {
        return [item.articleImages[0] !== undefined ? _c(VImg, {
          staticClass: "ma-1",
          attrs: {
            "height": "50px",
            "width": "50px",
            "contain": "",
            "src": item.articleImages[0].image
          },
          on: {
            "click": function ($event) {
              return _vm.openImage(item.articleImages);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.currencyFormatService.formatEuro(item.revenue)) + " ")];
      }
    }, {
      key: "item.amount",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.amount.toLocaleString('de-DE')) + " " + _vm._s(item.unit) + " ")];
      }
    }])
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.articleDetailsShow,
      expression: "articleDetailsShow"
    }]
  }, [_c('ArticleDetailsVK', {
    attrs: {
      "lfdnr": _vm.lfdnr,
      "articleTypeCode": _vm.articleTypeCode,
      "articleType": _vm.articleType,
      "articleCategory": _vm.articleCategory,
      "articleCategoryCode": _vm.articleCategoryCode,
      "article": _vm.article,
      "articleCode": _vm.articleCode
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };