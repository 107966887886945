import { mapState } from 'vuex';
import WarningDialog from '@/components/core/WarningDialog.vue';
// https://techformist.com/vuetify-input-field-date-picker/
// filters: https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component

export default {
  components: {
    WarningDialog
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      //TODO: getFunctions
      showInactiveContactPersons: false,
      menu: false,
      activeContact: [],
      deleteContactDialog: false,
      newContact: false,
      oldContact: {},
      showContact: false,
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      sortDesc: true,
      editorContent: '',
      addedContact: {},
      updatedContact: {},
      deleteContact: {},
      warningDialog: false,
      warnMessage: 'Wollen Sie diesen Ansprechpartner löschen?',
      actions: [],
      colsPerButton: 0,
      additionalData: [],
      expanded: [],
      singleExpand: false,
      dialogInputMethods: ['Manuell', 'E-Mail', 'Telefon'],
      headers: [{
        text: 'Vorname',
        align: 'start',
        value: 'vorname'
      }, {
        text: 'Nachname',
        value: 'nachname'
      }, {
        text: 'Abteilung',
        value: 'abteilungen'
      }, {
        text: 'Funktion',
        value: 'funktionen'
      }, {
        text: 'E-Mail',
        value: 'email'
      }, {
        text: 'Telefon mobil',
        value: 'tel_mobil'
      }, {
        text: 'Telefon 2',
        value: 'tel2'
      }, {
        text: 'Durchwahl',
        value: 'dw'
      }, {
        text: 'Haupt',
        value: 'hauptansprechpartner'
      }, {
        text: 'Aktionen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12em'
      }],
      editedIndex: -1,
      editedItem: {}
      // birthDataValidationRule: value => {
      //   const pattern = /^\d{4}-\d{2}-\d{2}$/
      //   return pattern.test(value) || 'Ungültiges Geburtsdatum'
      // }
    };
  },
  computed: {
    ...mapState({
      contacts: state => state.crmContacts.contacts,
      genders: state => state.crmContacts.genders,
      functions: state => state.crmContacts.functions,
      departments: state => state.crmContacts.departments
    }),
    filteredContacts() {
      if (this.showInactiveContactPersons) {
        return this.contacts;
      }
      return this.contacts.filter(c => c.active);
    }
  },
  methods: {
    init() {
      this.loadContacts();
      this.getGenders();
      this.getFunktionen();
      this.loadDepartments();
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true;
      }
      return false;
    },
    deleteItemConfirm() {
      this.remove(this.deleteContactPerson);
      this.warningDialog = false;
      this.deleteContactPerson = null;
    },
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage;
      this.actions = actions;
      this.warningDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action);
      this.warningDialog = false;
    },
    loadContacts() {
      this.$store.dispatch('loadContacts', {
        lfdnr: this.lfdnr
      }).then(this.loading = false);
    },
    getGenders() {
      this.$store.dispatch('getGenders');
    },
    getFunktionen() {
      this.$store.dispatch('getFunktionen');
    },
    loadDepartments() {
      this.$store.dispatch('getDepartments');
    },
    cancel() {
      this.activeContact = {
        ...this.oldContact
      };
    },
    close() {
      this.showContact = false;
    },
    save(x) {
      //Wenn Benutzer kein Geschlecht angibt, dann wird geschlecht auf "ohne" gesetzt
      if (this.activeContact.geschlecht == null) {
        this.activeContact.geschlecht = this.genders.filter(obj => {
          return obj.key.lfdnr === 0;
        })[0];
      }

      // if (this.activeContact.geburtsdatum != null) {
      //   if (this.activeContact.geburtsdatum.startsWith('000'))
      //     this.activeContact.geburtsdatum =
      //       '200' + this.activeContact.geburtsdatum.substring(3)
      //   else if (this.activeContact.geburtsdatum.startsWith('00'))
      //     this.activeContact.geburtsdatum =
      //       '19' + this.activeContact.geburtsdatum.substring(2)
      // }

      //Wenn neuer Kontakt angelegt werden soll
      if (x == 'new') {
        if (this.activeContact.active == false) {
          this.activeContact.active = 0;
        } else {
          this.activeContact.active = 1;
        }
        this.addedContact = {
          key: {
            firma: 1,
            kdnr: this.lfdnr,
            lfdnr: 1
          },
          titel: this.activeContact.titel,
          vorname: this.activeContact.vorname,
          nachname: this.activeContact.nachname,
          dw: this.activeContact.dw,
          geschlecht: this.activeContact.geschlecht.key.lfdnr,
          funktionen: this.activeContact.funktionen,
          email: this.activeContact.email,
          geburtsdatum: this.activeContact.geburtsdatum,
          tel_mobil: this.activeContact.tel_mobil,
          aussendung: this.activeContact.aussendung,
          active: this.activeContact.active,
          anmerkung: this.activeContact.anmerkung,
          hauptansprechpartner: this.activeContact.hauptansprechpartner,
          tel2: this.activeContact.tel2,
          fax: this.activeContact.fax,
          abteilungen: this.activeContact.abteilungen
        };
        this.$store.dispatch('saveContact', {
          lfdnr: this.lfdnr,
          wf_cid: this.wf_cid,
          contact: this.addedContact
        }).then(() => {
          this.loading = false;
        });

        //Wenn existierender Kontakt bearbeitet werden soll
      } else {
        this.updatedContact = {
          key: this.activeContact.key,
          titel: this.activeContact.titel,
          vorname: this.activeContact.vorname,
          nachname: this.activeContact.nachname,
          dw: this.activeContact.dw,
          geschlecht: this.activeContact.geschlecht.key.lfdnr,
          funktionen: this.activeContact.funktionen,
          email: this.activeContact.email,
          geburtsdatum: this.activeContact.geburtsdatum,
          tel_mobil: this.activeContact.tel_mobil,
          aussendung: this.activeContact.aussendung,
          active: this.activeContact.active == true ? 1 : 0,
          anmerkung: this.activeContact.anmerkung,
          hauptansprechpartner: this.activeContact.hauptansprechpartner,
          tel2: this.activeContact.tel2,
          fax: this.activeContact.fax,
          abteilungen: this.activeContact.abteilungen
        };
        this.$store.dispatch('updateContact', {
          lfdnr: this.lfdnr,
          wf_cid: this.wf_cid,
          contact: this.updatedContact
        }).then(() => {
          this.loading = false;
        });
      }
      this.showContact = false;
    },
    show(item) {
      if (this.newContact == true) {
        this.activeContact = {
          active: true
        };
      } else {
        var _this$activeContact$d;
        this.activeContact = {
          ...item
        };
        // Date fix in showing (only displaying date in YYYY-MM-DD format)
        if (this.activeContact.geburtsdatum != null) {
          this.activeContact.geburtsdatum = new Date(this.activeContact.geburtsdatum).toISOString().split('T')[0];
        }

        //get Gender of active Contact
        this.activeContact.geschlecht = this.genders.filter(obj => {
          return obj.key.lfdnr === this.activeContact.geschlecht;
        })[0];
        this.activeContact.department = (_this$activeContact$d = this.activeContact.department) === null || _this$activeContact$d === void 0 ? void 0 : _this$activeContact$d.department;

        //Kontakt vor Änderungen
        this.oldContact = Object.assign({}, this.activeContact);
      }
      this.showContact = true;
    },
    remove(item) {
      this.$store.dispatch('deleteContact', {
        lfdnr: this.lfdnr,
        key: item.key
      }).then(() => {
        this.loadContacts();
        this.deleteContactDialog = false;
      });
    },
    functionComboBoxFilter(item, queryText, itemText) {
      if (item.header) return false;
      const text = itemText == null ? '' : itemText;
      const query = queryText == null ? '' : queryText;
      if (query.startsWith('*')) {
        const editedQuery = query.toString().replace('*', '');
        return text.toString().toLowerCase().indexOf(editedQuery.toString().toLowerCase()) > -1;
      } else {
        return text.toString().toLowerCase().startsWith(query.toString().toLowerCase());
      }
    },
    formatContactPersonActive(item) {
      if (item != null && false == item.active) {
        return 'inactive-row font-italic disabled';
      }
    }
  },
  watch: {
    lfdnr() {
      this.init();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  mounted() {
    this.init();
  }
};