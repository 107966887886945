import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "alignInformationTab"
  }, [_c(VForm, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Kundennummer",
      "append-icon": "mdi-numeric",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.kdNr,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "kdNr", $$v);
      },
      expression: "address.supplier.kdNr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "UID",
      "append-icon": "mdi-identifier",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.uid,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "uid", $$v);
      },
      expression: "address.supplier.uid"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Lieferantengruppe",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.supplierGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "supplierGroupName", $$v);
      },
      expression: "address.supplier.supplierGroupName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Verband",
      "prepend-inner-icon": "mdi-account-group",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.associationName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "associationName", $$v);
      },
      expression: "address.supplier.associationName"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Branche",
      "prepend-inner-icon": "mdi-account-hard-hat",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.industrialSectorName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "industrialSectorName", $$v);
      },
      expression: "address.supplier.industrialSectorName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Fibugruppe",
      "prepend-inner-icon": "mdi-account-group",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.fibuGroupName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "fibuGroupName", $$v);
      },
      expression: "address.supplier.fibuGroupName"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Lieferung und Bestellung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Versandart",
      "prepend-inner-icon": "mdi-truck",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.shippingMethodName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "shippingMethodName", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.shippingMethodName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Lieferbedingung",
      "prepend-inner-icon": "mdi-truck-alert",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.shippingConditionName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "shippingConditionName", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.shippingConditionName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "PA auf Anfrage",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.priceOnDemand,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "priceOnDemand", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.priceOnDemand"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "PA auf Bestellung",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.priceOnOrder,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "priceOnOrder", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.priceOnOrder"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Rechnungssperre",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.invoiceBlocking,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "invoiceBlocking", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.invoiceBlocking"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "outlined": "",
      "label": "Sammelliefers. Int.",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.collectiveDeliveryNoteInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "collectiveDeliveryNoteInterval", $$v);
      },
      expression: "\n            address.supplier.deliveryAndOrder.collectiveDeliveryNoteInterval\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "outlined": "",
      "label": "Sammelr. Int.",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.collectiveInvoiceInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "collectiveInvoiceInterval", $$v);
      },
      expression: "\n            address.supplier.deliveryAndOrder.collectiveInvoiceInterval\n          "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Filialdirektbel.",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.directBranchShipping,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "directBranchShipping", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.directBranchShipping"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Kundendirektbel.",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.directCustomerDelivery,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "directCustomerDelivery", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.directCustomerDelivery"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Verband Mwst.",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.associationVat,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "associationVat", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.associationVat"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Kreditorennummer",
      "append-icon": "mdi-numeric",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.creditorNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "creditorNumber", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.creditorNumber"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Anbruchpackungen",
      "prepend-inner-icon": "mdi-package-variant",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.openedPacks,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "openedPacks", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.openedPacks"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Dezimalstellen",
      "append-icon": "mdi-decimal-comma",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.decimalPlaces,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "decimalPlaces", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.decimalPlaces"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Mindestbestellwert",
      "append-icon": "mdi-currency-eur",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.minimumOrderValue,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "minimumOrderValue", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.minimumOrderValue"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Bezahlung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-cash",
      "item-text": "description",
      "item-value": "id",
      "label": "Zahlungsbedingung",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.paymentConditionsName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "paymentConditionsName", $$v);
      },
      expression: "address.supplier.payment.paymentConditionsName"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-cash",
      "item-text": "description",
      "item-value": "id",
      "label": "Zahlungsart",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.paymentTypesName,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "paymentTypesName", $$v);
      },
      expression: "address.supplier.payment.paymentTypesName"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-credit-card",
      "label": "IBAN",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.iban,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "iban", $$v);
      },
      expression: "address.supplier.payment.iban"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-numeric",
      "label": "BIC",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.bic,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "bic", $$v);
      },
      expression: "address.supplier.payment.bic"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Sperre Zahlungsvorschlag",
      "prepend-icon": "mdi-lock",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.lockPaymentProposal,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "lockPaymentProposal", $$v);
      },
      expression: "address.supplier.payment.lockPaymentProposal"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "label": "Einzugsermächtigungsdatum",
            "append-icon": "mdi-calendar",
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }])
  }), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Rabattart",
      "prepend-inner-icon": "mdi-percent",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "discountType", $$v);
      },
      expression: "address.supplier.payment.discountType"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "2",
      "lg": "2",
      "xl": "2",
      "md": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Rabattausmaß",
      "suffix": "%",
      "disabled": ""
    },
    model: {
      value: _vm.address.supplier.payment.discount,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "discount", $$v);
      },
      expression: "address.supplier.payment.discount"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };